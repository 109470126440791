import { WordList } from "./words.ts";

class Word {
  word: string;
  x: number;
  y: number;
  element: HTMLElement;
  wordNumber: number;

  static wordInstances: Map<string, number> = new Map<string, number>();
  static elementToWord: Map<HTMLElement, Word> = new Map<HTMLElement, Word>();

  constructor(word: string, x: number, y: number) {
    this.word = word;
    this.x = x;
    this.y = y;
    this.wordNumber = Word.wordInstances.get(word) ?? 0;

    this.element = document.createElement("div");
    this.element.classList.add("type-word");
    this.element.classList.add(word);
    this.element.id = word + "-" + this.wordNumber;
    this.element.innerHTML = word;
    this.element.draggable = true;
    this.element.style.position = "absolute";
    this.element.addEventListener("dragstart", handleDragStart);
    this.element.addEventListener("dragend", handleDragEnd);
    this.element.addEventListener("drag", handleDrag);
    this.element.style.left = x + "px";
    this.element.style.top = y + "px";

    Word.wordInstances.set(word, this.wordNumber + 1);
    Word.elementToWord.set(this.element, this);
  }

  // Currently unused - forces words that this collides to move.
  processCollisions() {
    const thisCenterX =
      this.element.getBoundingClientRect().x +
      this.element.getBoundingClientRect().width / 2;
    const thisCenterY =
      this.element.getBoundingClientRect().y +
      this.element.getBoundingClientRect().height / 2;

    for (const word of Word.elementToWord.values()) {
      if (
        this !== word &&
        rectIntersects(
          this.element.getBoundingClientRect(),
          word.element.getBoundingClientRect()
        )
      ) {
        const wordCenterX =
          word.element.getBoundingClientRect().x +
          word.element.getBoundingClientRect().width / 2;
        const wordCenterY =
          word.element.getBoundingClientRect().y +
          word.element.getBoundingClientRect().height / 2;

        const deltaX = wordCenterX - thisCenterX;
        const deltaY = wordCenterY - thisCenterY;
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

        word.element.style.left = `${
          word.element.getBoundingClientRect().x + (deltaX / distance) * 30
        }px`;
        word.element.style.top = `${
          word.element.getBoundingClientRect().y + (deltaY / distance) * 20
        }px`;
      }
    }
  }
}

function clearPoem() {
  const poem = document.getElementById("poem");
  if (poem) {
    poem.innerHTML = "";
  }
  saveWordsInBox();
}
window.clearPoem = clearPoem;

function handleDragStart(this: HTMLElement, event: DragEvent) {
  this.dataset.tempLeft = String(
    event.clientX - (event.target as HTMLElement).getBoundingClientRect().x
  );
  this.dataset.tempTop = String(
    event.clientY - (event.target as HTMLElement).getBoundingClientRect().y
  );

  if (event.dataTransfer) {
    event.dataTransfer.setData(
      "text/plain",
      // "word1,x,y;"
      `${this.id};${
        event.clientX - (event.target as HTMLElement).getBoundingClientRect().x
      };${
        event.clientY - (event.target as HTMLElement).getBoundingClientRect().y
      }`
    );
  } else {
    console.log("Error: no dataTransfer object in handleDragStart");
  }
}

function handleDrag(this: HTMLElement, event: DragEvent) {
  const hasMovement =
    event.clientX !== Number(this.dataset.lastX) ||
    event.clientY !== Number(this.dataset.lastY);
  this.dataset.lastX = String(event.clientX);
  this.dataset.lastY = String(event.clientY);

  // "physics" time!
  // only enable if actively dragging
  // currently disabled due to performance and correctness reasons
  if (!hasMovement || true) {
    return;
  }

  const wordElements = Array.from(document.getElementsByClassName("type-word"));
  const srcElement = event.srcElement as HTMLElement;

  let tempLeft = Number(srcElement.dataset.tempLeft);
  let tempTop = Number(srcElement.dataset.tempTop);

  const curBoundingRect = this.getBoundingClientRect();
  curBoundingRect.x = event.clientX - tempLeft;
  curBoundingRect.y = event.clientY - tempTop;

  const intersectingElements = [];

  const elementToDisplacement = new Map<Element, [number, number]>();

  for (const wordElement of wordElements) {
    const wordRect = wordElement.getBoundingClientRect();
    if (this === wordElement) {
      continue;
    }

    if (rectIntersects(curBoundingRect, wordRect)) {
      intersectingElements.push(wordElement);

      const deltaX = curBoundingRect.x - wordRect.x;
      const deltaY = curBoundingRect.y - wordRect.y;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      elementToDisplacement.set(wordElement, [
        (deltaX / distance) * 0.1,
        (deltaY / distance) * 0.1,
      ]);
    }
  }

  for (const baseElement of intersectingElements) {
    console.log(intersectingElements.length);
    const baseRect = baseElement.getBoundingClientRect();
    for (const wordElement of wordElements) {
      const wordRect = wordElement.getBoundingClientRect();
      if (baseElement === wordElement) {
        continue;
      }

      if (rectIntersects(baseRect, wordRect)) {
        const deltaX = baseRect.x - wordRect.x;
        const deltaY = baseRect.y - wordRect.y;
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

        const [baseDeltaX, baseDeltaY] = elementToDisplacement.get(
          baseElement
        ) ?? [0, 0];
        elementToDisplacement.set(wordElement, [
          (deltaX / distance) * 0.1 + baseDeltaX,
          (deltaY / distance) * 0.1 + baseDeltaY,
        ]);
      }
    }
  }

  for (const [element, [deltaX, deltaY]] of elementToDisplacement.entries()) {
    const wordRect = element.getBoundingClientRect();
    element.style.left = `${wordRect.x - deltaX - 2}px`;
    element.style.top = `${wordRect.y - deltaY - 2}px`;
  }
}

function handleDragEnd(this: HTMLElement, event: DragEvent) {
  const poemEl = document.getElementById("poem");
  const poemRect = poemEl?.getBoundingClientRect();
  const wordRect = (event.srcElement as HTMLElement)?.getBoundingClientRect();

  const last_x = Number(this.dataset.lastX);
  const last_y = Number(this.dataset.lastY);

  if (Number.isNaN(last_x) || Number.isNaN(last_y)) {
    console.log("Error: last_x or last_y is NaN");
    return;
  }

  const srcElement = event.srcElement as HTMLElement;
  const tempLeft = Number(srcElement.dataset.tempLeft);
  const tempTop = Number(srcElement.dataset.tempTop);

  if (Number.isNaN(tempLeft) || Number.isNaN(tempTop)) {
    console.log("Error: tempLeft or tempTop is undefined");
  }

  const isClickInBox =
    poemRect &&
    last_x > poemRect.x &&
    last_y > poemRect.y &&
    last_x < poemRect.x + poemRect.width &&
    last_y < poemRect.y + poemRect.height;

  let newX, newY;

  // the -3 is somehow related to the border width or something. It keeps the div centered.
  if (srcElement.dataset.destId !== "poem") {
    newX = last_x - tempLeft - 2;
    newY = last_y - tempTop - 2;
  } else {
    const poemRect = poemEl?.getBoundingClientRect(); // Add null check for poemRect
    newX = last_x - (poemRect?.x ?? 0) - tempLeft - 2;
    newY = last_y - (poemRect?.y ?? 0) - tempTop - 2;
  }

  srcElement.style.left = `${newX}px`;
  srcElement.style.top = `${newY}px`;

  srcElement.dataset.tempLeft = "0";
  srcElement.dataset.tempTop = "0";

  // Modify our internal Word representation
  const word = Word.elementToWord.get(srcElement);
  if (word) {
    word.x = newX;
    word.y = newY;
  }

  saveWordsInBox();
}

function rectIntersects(rect1: DOMRect, rect2: DOMRect) {
  return (
    rect1.x + rect1.width > rect2.x &&
    rect1.y + rect1.height > rect2.y &&
    rect1.x < rect2.x + rect2.width &&
    rect1.y < rect2.y + rect2.height
  );
}

function rectContains(bigrect: DOMRect, smallrect: DOMRect) {
  return (
    smallrect.x > bigrect.x &&
    smallrect.y > bigrect.y &&
    smallrect.x + smallrect.width < bigrect.x + bigrect.width &&
    smallrect.y + smallrect.height < bigrect.y + bigrect.height
  );
}

function isWordInBox(wordEl: HTMLElement) {
  const poemEl = document.getElementById("poem");
  const poemRect = poemEl?.getBoundingClientRect();
  const wordRect = wordEl?.getBoundingClientRect();

  if (poemRect && wordRect) {
    return rectContains(poemRect, wordRect);
  }

  return false;
}

function saveWordsInBox() {
  let wordsString = "/?words=";
  const poemEl = document.getElementById("poem");
  const poemRect = poemEl?.getBoundingClientRect();

  if (poemRect === undefined) {
    console.log("Error: poemRect is undefined");
    return;
  }

  Array.from(document.getElementsByClassName("type-word")).forEach((wordEl) => {
    const wordRect = wordEl?.getBoundingClientRect();

    if (wordEl.parentNode && (wordEl.parentNode as HTMLElement).id === "poem") {
      // "word,x,y;"
      wordsString += `${wordEl.innerHTML},${Math.round(
        wordRect.x - poemRect.x - 3
      )},${Math.round(wordRect.y - poemRect.y - 3)};`;
    }
  });

  history.replaceState(null, "", wordsString);
}

// Put all strings in WordList into divs on the webpage
function addStringsToPage(wordList: string[]) {
  const body = document.getElementsByTagName("body")[0];
  const poem = document.getElementById("poem");

  // Parse the URL - put any of those words in the right spots
  const wordsString = window.location.search.split("=")[1];
  const wordsToSkip = new Map<string, number>();
  const wordsOccurrence = new Map<string, number>();

  // There might not be a URL path
  if (wordsString !== undefined && wordsString.length > 1) {
    // remove the final semicolon
    const urlWords = wordsString
      .substring(0, wordsString.length - 1)
      .split(";");

    urlWords.forEach((wordSet) => {
      const [word, xcor, ycor] = wordSet.split(",");

      wordsToSkip.set(word, (wordsToSkip.get(word) ?? 0) + 1);
      wordsOccurrence.set(word, (wordsOccurrence.get(word) ?? 0) + 1);

      const wordObj = new Word(word, Number(xcor), Number(ycor));
      poem?.appendChild(wordObj.element);
    });
  }

  const bodyRect = document
    .getElementsByTagName("body")[0]
    .getBoundingClientRect();

  wordList.forEach((word) => {
    const skipCount = wordsToSkip.get(word);
    if (skipCount !== undefined && skipCount > 0) {
      wordsToSkip.set(word, skipCount - 1);
    } else {
      wordsOccurrence.set(word, (wordsOccurrence.get(word) ?? 0) + 1);

      const wordObj = new Word(word, 0, 0);
      body?.appendChild(wordObj.element);

      const poemRect = poem?.getBoundingClientRect();
      if (poemRect === undefined) {
        console.log("Error: poemRect is undefined");
        return;
      }
      do {
        wordObj.element.style.left =
          Math.random() * bodyRect.width * 0.9 + 50 + "px";
        wordObj.element.style.top =
          Math.random() * bodyRect.height * 0.9 + 50 + "px";
      } while (
        rectIntersects(poemRect, wordObj.element.getBoundingClientRect())
      );
    }
  });
}

// Turns a list of words into a URL query string
function getQueryString(wordList: string[]) {
  return wordList.reduce((acc, word) => {
    return acc + "-" + word;
  });
}

// Turns a URL query string into a list of words
function getWordListFromQueryString(queryString: string) {
  return queryString.split("-");
}

const dropAreaIds = ["poem", "container"];

const body = document.getElementsByTagName("body")[0];
body.addEventListener("dragover", (event) => {
  event.preventDefault();
  return false;
});
body.addEventListener("drop", (event) => {
  event.preventDefault();
  const dataTransfer = event.dataTransfer;
  if (dataTransfer) {
    const dataList = dataTransfer.getData("text/plain").split(";");
    dataTransfer.setData("text/plain", dataList.join(";"));
    const element = document.getElementById(dataList[0]);

    if (element) {
      element.dataset.tempLeft = dataList[1];
      element.dataset.tempTop = dataList[2];
    } else {
      console.log("Error: dropped element not found");
      return false;
    }

    try {
      const targetElement = event.target as HTMLElement;
      if (
        Array.from(targetElement.classList).find(
          (className) => className === "type-word"
        ) !== undefined
      ) {
        targetElement.parentElement?.appendChild(element);
        element.dataset.destId = targetElement.parentElement?.id;
      } else {
        targetElement.appendChild(element);
        element.dataset.destId = targetElement.id;
      }
    } catch (error) {
      console.warn(error);
    }
  }

  return false;
});

addStringsToPage(WordList);
